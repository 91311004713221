.preview-inner-content {
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 360px;
  width: 100%;
  text-align: center;
}

.preview-inner-without-image {
  background-color: #135f82;
  padding: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.title-question-container {
  text-align: center;
  margin-top: 24px;
  padding: 10px;

  .title-question-text {
    margin: 0;
    font-weight: normal;
    color: #fff;
    font-family: PlayfairDisplay-VariableFont_wght;
    font-size: 9.6px;
  }

  h1.title-question-text {
    font-size: 13.6px;
  }
}

.container-answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &.container-answers-with-image {
    padding-top: 20px;
  }

  .answer-item {
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn-answers {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 78px;
  height: 78px;
  padding: 6px;
  border-radius: 20px;
  border: white;

  background-image: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  font-size: 8px;
  font-family: "PlayfairDisplay-VariableFont_wght";
  text-transform: uppercase;
  cursor: pointer;
}

.btn-answer-selected {
  border: 2.4px solid #b57c00;
}

.btn-smell {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: none;
  background-color: #b57c00;
  background-size: cover;
  color: white;
  font-size: 6.8px;
  cursor: pointer;
}

.btn-next {
  width: 44px;
  border: none;
  background-color: #c4c4c4;
  background-size: cover;
  color: white;
  cursor: pointer;
}

.btn-next-with-image {
  height: 44px;
  border-radius: 50%;
  font-size: 6.4px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.btn-next-without-image {
  border-radius: 4px 0 0 4px;
  padding: 4px;
  font-size: 6.8px;
}

.btn-next-enabled {
  background-color: #b57c00;
}
