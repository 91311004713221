h1 {
  color: rgb(0, 0, 0);
  font-family: verdana;
  font-size: 16px;
}
h2.header {
  text-align: left;
  font-size: 16px;
}
h3.title {
  font-size: 18px;
  background-color: #ffffff;
  text-align: center;
}
h2.titleLeft {
  font-size: 16px;
  text-align: left;
  font-weight: 100;
}
h4.titleLeft {
  font-size: 12px;
  text-align: left;
  font-weight: 100;
  line-height: 0.5;
  margin-bottom: 10px;
}
h4.labels {
  font-weight: 100;
  color: rgb(0, 0, 0);
  font-family: Arial;
  font-size: 12px;
}
h4.fix {
  font-weight: 100;
  color: rgb(0, 0, 0);
  font-family: Arial;
  font-size: 8px;
  line-height: 0.5;
  margin-bottom: 10px;
}
img.folhaLogo {
  width: 30mm;
  float: left;
  position: absolute;
  left: 1cm;
  top: 3cm;
}
img.graphicDescription {
  max-width: 150mm;
  min-width: 100mm;
}
div.headerPdf {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
div.container {
  flex: none;
  background-color: rgb(255, 255, 255);
  margin-top: 50px;
}
div.solid {
  border-style: solid;
  orientation: landscape;
  border-width: 0.02cm;
}
div.folha {
  margin-top: 0.5cm;
}
div.observation {
  margin-top: 0.5cm;
  margin-left: 0.5cm;
}
div.fix {
  margin-top: 0.5cm;
  margin-bottom: 1cm;
}
div.footerDescription {
  margin-top: 0.5cm;
  display: inline-flexbox;
  align-items: center;
  justify-content: center;
}
div.tableDescription {
  margin-top: 0.5cm;
  display: inline-table;
  margin-left: 0.5cm;
}
table.tableDescription {
  border-collapse: collapse;
  width: 250;
}

th.tableDescription, td.tableDescription {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
div.tableClassification {
  margin-top: 0.5cm;
  display: inline-table;
  margin-left: 0.5cm;
  align-items: center;
  justify-content: center;
}

div.imgDescription {
  margin-top: 0.5cm;
  display: flex;
  justify-content: center;
  align-items: center;
}

td.result,
th.result {
  border: 1px solid;
  text-align: center;
}
table.top {
  width: 100%;
  border: none;
}
td.top,
th.top {
  border: 0px solid;
  text-align: left;
  width: 9cm;
  margin-right: 5px;
}

table.result {
  width: 100%;
  border-collapse: collapse;
}
div.containerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
table.description {
  width: 100%;
  border-collapse: collapse;
}
td.description,
th.description {
  border: 1px solid;
  text-align: left;
  margin-left: 5px;
}
div.meio {
  display: flex;
  justify-content: center;
  align-items: center;
}

table.tableObs {
  border-collapse: collapse;
  margin-top: 0.5cm;
  border: 1px solid #ddd;
  width: 115mm;
}
th.tableObs, td.tableObs {
  padding: 8px;
  text-align: left;
  font-size: 10px
}

@media print {
  img.folhaLogo {
    width: 30mm;
    float: left;
    position: absolute;
    left: 1cm;
    top: 1cm;
  }
  div.footerDescription {
    margin-top: 0.4cm;
    display: inline-flexbox;
    align-items: center;
    justify-content: center;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    break-before: page;
  }
  .meio .tableClassification {
    width: 440px;
  }
  .meio .MuiTable-root:nth-child(2) {
    width: 115mm;
  }
  .meio .MuiTable-root:nth-child(2) tr {
    padding: 8px;
    font-size: 9px;
    line-height: 1.1em;
    background-color: pink;

    text-align: left;
  }
}

@page {
  size: auto;
  margin: 10mm;
}
