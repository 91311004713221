.privacy-page {
  color: #000;
  padding: 15px 5px;
}

.privacy-page h1 {
  text-align: center;
}
.privacy-page h2 {
  text-align: left;
  padding-left: 3rem;
  padding-right: 3rem;
}
.privacy-page h6 {
  text-align: left;
  padding-left: 8rem;
}

.privacy-page p {
  text-align: justify;
  padding-left: 3rem;
  padding-right: 3rem;
}
