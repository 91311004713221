/* Estilo para navegadores baseados em WebKit (Chrome, Safari, etc.) */
input[type="range"] {
  -webkit-appearance: none; /* Remove o estilo padrão */
  appearance: none;
  width: 80%; /* Largura do slider */
  height: 12px; /* Altura do controle do slider, maior que o track para centralizar o thumb */
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px; /* Espessura da barra */
  background: #b3b3b3; /* Cor da barra não selecionada */
  border-radius: 5px; /* Bordas arredondadas */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px; /* Largura do thumb */
  height: 10px; /* Altura do thumb */
  background: #ff5c33; /* Cor do thumb */
  cursor: pointer; /* Altera o cursor para a mão */
  border-radius: 50%; /* Thumb arredondado */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* Pequeno sombreamento no thumb */
  transform: translateY(-30%); /* Centraliza o thumb na trilha */
}

/* Personalizando a parte selecionada da trilha no WebKit */
input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #ff5c33 0%, #ff5c33 var(--val), #b3b3b3 var(--val), #b3b3b3 100%);
}

input[type="range"] {
  --val: 50%; /* Altere dinamicamente com JavaScript, inicialmente 50% */
}

/* Estilo para navegadores Firefox */
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px; /* Espessura da barra */
  background: #b3b3b3; /* Cor da barra não selecionada */
  border-radius: 5px; /* Bordas arredondadas */
}

input[type="range"]::-moz-range-thumb {
  width: 10px; /* Largura do thumb */
  height: 10px; /* Altura do thumb */
  background: #ff5c33; /* Cor do thumb */
  border-radius: 50%; /* Thumb arredondado */
  cursor: pointer; /* Altera o cursor para a mão */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* Pequeno sombreamento no thumb */
  transform: translateY(-30%); /* Centraliza o thumb na trilha */
}

/* Personalizando a parte selecionada da trilha no Firefox */
input[type="range"]::-moz-range-progress {
  background-color: #ff5c33; /* Cor da parte selecionada */
}

/* Estilo para navegadores Microsoft Edge e IE */
input[type="range"]::-ms-track {
  width: 100%;
  height: 4px; /* Espessura da barra */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #ff5c33; /* Cor da parte selecionada */
  border-radius: 5px;
}

input[type="range"]::-ms-fill-upper {
  background: #b3b3b3; /* Cor da parte não selecionada */
  border-radius: 5px;
}

input[type="range"]::-ms-thumb {
  width: 10px;
  height: 10px;
  background: #ff5c33;
  border-radius: 50%;
  cursor: pointer;
  transform: translateY(-30%); /* Centraliza o thumb na trilha */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
