@import "../../../../../_metronic/_assets/sass/config";

.preview-container{
    background-color: kt-get($kt-state-colors, light, base);
    border-style: solid;
    border-width: 0.8px;
    border-color: kt-get($kt-state-colors, primary, base);
    padding-bottom: 5rem;
    padding-left: 2rem;
    color: kt-get($kt-state-colors, dark, base) !important;
    border-radius:3px;

}

.editor{
    padding-left: 3rem;
    background-color: kt-get($kt-state-colors, light, base);
    color: kt-get($kt-state-colors, dark, base);
    border-width: 0.8px;
    border-style: solid; 
    border-radius:3px;
    border-color: kt-get($kt-state-colors, primary, base);
}

.icon-toolbar{
    color: kt-get($kt-state-colors, dark, base);
    background-color: kt-get($kt-state-colors, light, base);
    height: 1.6rem;
    border-width: 0.8px;
    border-color: kt-get($kt-state-colors, primary, base);
    border-radius:3px;
    &:hover{ 
        border-style: solid;
        cursor: pointer;
    }

    .icon-clicked{
        height: 1.6rem;
        border-width: 0.8px;
        border-style: solid; 
        border-radius:3px;
        width: 1.6rem;
    }
    &:hover{ 
        border-style: none;
        cursor: pointer;
    }
}


