$gray800: #262626;
$blue-gray: #ebedf2;
$border: 1px solid;

.card-form {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-top: 12px;
  &,
  .input-form,
  .label-form,
  .custom-select {
    background-color: inherit;
  }
  .header-form {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.55rem 1rem;
    font-size: 1rem;
    color: inherit;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    &.header-form-dark {
      background-color: #262626;
    }
  }
  .span-input {
    color: red;
  }
  .input-form {
    margin-bottom: 10px;
  }
  .custom-select {
    color: inherit;
  }
  .input-form-dark,
  .select-border-dark {
    border: $border $gray800;
    outline: $border $gray800;
  }
  .input-form-light,
  .select-border-light {
    border: $border $blue-gray;
    outline: $border $blue-gray;
  }
  .input-form-dark {
    color: white;
  }
  .select-border-dark option {
    background-color: #1c1c1c;
  }
}
.card-body {
  padding: 1rem;
}
.form-dark.card-form {
  border-color: $gray800;
}
.form-light.card-form {
  border-color: $blue-gray;
}
