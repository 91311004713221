@import "../../../../../_metronic/_assets/sass/config";


.input-modal-update-password{
    width: 90px;
}

.container-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: fit-content;
    width: 350px;
    margin: 0 auto;
    @media(max-width: 600px) {
        margin: 0 20px;
    }
    background-color: kt-get($kt-state-colors, light, base);
    border-radius: 15px;
    padding: 20px;

    span {
        display: block;
    }

  .title {
    color: #6e5221;
    font-size: 18px;
    font-weight: bold;
  }

  .subtitle {
    color: #6e5221;
    font-size: 14px;
    margin: 15px 0 30px;
  }
}