.collectionContainer {
  gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.collectionDevice {
  min-width: 25%;
  box-sizing: border-box;
}

.table-fragrances {
  width: 100%;
  border-collapse: collapse;
}

.table-fragrances th, .table-fragrances td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.table-fragrances tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.table-dark {
  background-color: #333;
  color: #fff;
}

.table-dark th, .table-dark td {
  border: 1px solid #444;
  padding: 10px;
  text-align: left;
}

.table-dark thead {
  background-color: #222;
}

.table-dark tbody tr:nth-child(even) {
  background-color: #424242;
}

.table-dark tbody tr:nth-child(odd) {
  background-color: #383838;
}

.table-dark tbody tr:hover {
  background-color: #555;
}

.table-light {
  background-color: #fff;
  color: #333;
}

.table-light th, .table-light td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.table-light thead {
  background-color: #e9ecef;
}

.table-light tbody tr:nth-child(even) {
  background-color: #efefef;
}

.table-light tbody tr:hover {
  background-color: #d9d9d9;
}

@media (max-width: 768px) {
  .table-fragrances {
    display: block;
    overflow-x: auto;
  }
}
