.form-label {
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.invalid-input {
  color: red;
}

.btb-success {
  background-color: #677a88;
  color: white;
}
