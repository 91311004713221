.campaign-flags {
  img {
    width: 40px;
  }
}

.collection-device {
  .legends {
    padding-left: 6.5rem;

    @media only screen and (max-width: 650px) {
      padding-left: 0rem;
    }

    li {
      color: #343a40;
      list-style-type:none;
      display: inline;
      margin-left: 10px;

      &.configured {
        color: #5578EB;
        span {
          background-color: #5578EB;
        }
      }

      &.not-configured {
        color: #6b747b;
        span {
          background-color: #6b747b;
        }
      }

      &.selected {
        color: #ce8300;
        span {
          background-color: #ce8300;
        }
      }

      span {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
      }

    }
  }

  .device {
    height: 400px;
    padding-bottom: 3%;
  }

  .table-responsive {
    max-height: 350px;

    .fragrance-preview {
      max-width: 40px;
      margin-right: 5px;
      border: 1px solid #ce8300;
    }
  }
}

td.is-default-campaign {
  > div {
    display: inline-block;
  }

  img {
    cursor: pointer;
    width: 15px;
    vertical-align: sub;
  }
}
